<template>
    <div class="approvals-list-header">
        <div class="column-name">{{t('name')}}</div>

        <div class="column-order">{{t('order')}}</div>

        <div class="column-details">{{t('details')}}</div>

        <!-- Editable approval config columns -->
        <div v-for="field in editableFields" class="column-editable">{{field.label}}</div>

        <!-- Approval call to action -->
        <div class="column-cta">{{t('cta')}}</div>
    </div>
</template>

<script>
    export default {
        name: "ApprovalsListHeader",
        props: {
            editableFields: {
                type: Array,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.approvals.list.header"
            }
        }
    }
</script>