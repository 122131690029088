export default {
    400: {
        title: "Bad Request",
        header: "@:views.errors.400.title",
        html: "Bad Request. Please try again later."
    },
    401: {
        title: "We've encountered an error on login",
        header: "@:views.errors.401.title",
        html: "Please contact <a href='{email}' target='_blank'>support</a>."
    },
    404: {
        title: "Page Not Found",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Eligibility Requirements",
        header: "Looks like you’re a bit early…",

        contact: "<p class='text-left'>Thank you for your interest in DXC Gear. It appears that you have reached the site before being enabled and invited directly. DXC Gear is being rolled out in stages country by country and by groups of users once regulatory requirements are worked through and as inventory is in place. When you are activated, you will receive an email containing a personal invite with instructions.</p><p class='text-left'>Click on <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Support</a> for questions or issues with your order or DXC Gear.</p>",

        subject: "DXC Gear - Eligibility",

        html: "<p class='text-left'> @:views.errors.403.eligibility @:views.errors.403.contact </p>",
        error: {
            "user-disabled": {
                header: "Access Not Approved",
                html: "<p class='text-left'>Thank you for your interest in DXC Gear. Currently you are not eligible.</p><p class='text-left'>If you have further questions, please click <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Support</a>.</p>"
            },
            "non-employees": {
                header: "Access Not Approved",
                html: "<p class='text-left'>Thank you for your interest in DXC Gear. Currently this is only available to DXC Employees. If you are a contractor with DXC, please contact your sponsoring manager for any equipment needs.</p><p class='text-left'>If you have further questions, please click <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Support</a>.</p>"
            },
            eligibility: {
                subject: "DXC Gear - Eligibility",
                header: "Looks like you're a bit early...",
                html: "<p class='text-left'>Thank you for your interest in DXC Gear. It appears that you have reached the site before being enabled and invited directly. DXC Gear is being rolled out in stages country by country and by groups of users once regulatory requirements are worked through and as inventory is in place. When you are activated, you will receive an email containing a personal invite with instructions.</p><p class='text-left'>Click on <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Support</a> for questions or issues with your order or DXC Gear.</p>",
            },
            "eligibility-location": {
                header: "DXC Gear not available in your country...",
                html: "<p class='text-left'>Thank you for your interest in DXC Gear but at this time there are no plans to release DXC Gear in your country.</p><p class='text-left'>For more information on PC accessory ordering, please refer to the <b><a href='https://csc.service-now.com/sp?sys_kb_id=3724e21cdb224b08d417e1994b9619e7&id=kb_article_view&sysparm_rank=1&sysparm_tsqueryId=3a89aab687965d1811140f2acebb3537' target='_blank' rel='noopener'>Order and Replace PC Accessories</a></b> article.</p>",
            },
            country: {
                header: "@:views.errors.403.header",
                html: " @:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "not-allowed": {
                header: "Access Not Approved",
                html: "<p class='text-left'>Thank you for your interest in DXC Gear. Currently this is only available to DXC Employees.</p><p class='text-left'>If you have further questions, please click <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Support</a>.</p>"
            },
            "no-permissions": {
                header: "No Access",
                subject: "No Access",
                html: "You do not have permissions to open this page.",
            }
        }
    },
    500: {
        title: "Unexpected error",
        header: "@:views.errors.500.title",
        message: "An error occurred. Please try again later."
    },
    code: {
        title: "Unexpected error",
        message: "@:views.errors.code.title",
        error: "Error {code}."
    }
}