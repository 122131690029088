export default {
    title: "Encomenda submetida",
    header: {
        success: "@:views.order.submitted.title",
        error: "Algo deu errado",
    },
    message: {
        success: "Seu pedido foi enviado. Para atualizações, verifique seu e-mail ou o painel na página <a href='{url}'>inicial</a> .",
        error: {
            stock: "Seu pedido foi feito, <b>exceto</b> os itens listados abaixo, pois estão esgotados. Faça seleções diferentes para solicitar esses itens. Para atualizações, verifique seu e-mail ou o painel na página <a href='{url}'>inicial</a> .",
            status: "Temos os detalhes do seu pedido e um agente de suporte da GEAR entrará em contato com você dentro de 3 dias úteis com as próximas etapas"
        }
    }
}