<template>
    <div class="column-details approvals-list-item-details">
        <!-- Details -->
        <div v-html="details"></div>

        <!-- Price -->
        <div v-if="null !== price" class="mt-3">${{price}}</div>
    </div>
</template>

<script>

    import {sanitize} from "@/helpers/html";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    export default {
        name: "ApprovalsListItemDetails",
        props: {
            approval: {
                type: Object,
                required: true
            }
        },
        computed: {
            details() {

                return sanitize(
                    getTranslationValue(this.approval.order, "details", this.$i18n)
                );
            },
            price() {

                if (this.approval.order.cost && 1 === parseInt(process.env.VUE_APP_VIEW_APPROVAL_ORDER_PRICE)) {

                    return this.approval.order.cost;
                }

                return null;
            }
        }
    }
</script>

