export default {
    400: {
        title: "Falha na solicitação",
        header: "@:views.errors.400.title",
        html: "Falha na solicitação. Por favor tente mais tarde."
    },
    401: {
        title: "Encontramos um erro no Login",
        header: "@:views.errors.401.title",
        html: "Por favor entre em contato com o  <a href='{email}' target='_blank'>suporte</a>."
    },
    404: {
        title: "Página não encontrada",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Requisitos de qualificação",
        header: "Parece que você está um pouco adiantado(a)…",

        contact: "<p class='text-left'>Obrigado por seu interesse na DXC Equipamentos. Parece que você chegou ao site antes de ser habilitado e convidado diretamente. A DXC Equipamentos está sendo lançada em etapas país por país e por grupos de usuários uma vez que as exigências regulatórias tenham sido cumpridas e que o inventário tenha sido feito. Quando você for ativado(a), receberá um e-mail contendo um convite pessoal com as instruções</p><p class='text-left'>Clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Suporte</a> para perguntas ou problemas com seu pedido ou DXC Equipamentos.</p>",

        subject: "DXC Equipamentos - Elegibilidade",

        html: "<p class='text-left'> @:views.errors.403.eligibility @:views.errors.403.contact </p>",
        error: {
            "user-disabled": {
                header: "Acesso não aprovado",
                html: "<p class='text-left'>Obrigado pelo seu interesse no DXC Gear. Atualmente você não é elegível.</p><p class='text-left'>Se você tiver mais dúvidas, clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Apoiar</a>.</p>"
            },
            "non-employees": {
                header: "Acesso não aprovado",
                html: "<p class='text-left'>Obrigado pelo seu interesse no DXC Gear. Atualmente, isso está disponível apenas para funcionários da DXC. Se você for um contratado da DXC, entre em contato com seu gerente patrocinador para qualquer necessidade de equipamento.</p><p class='text-left'>Se tiver mais dúvidas, clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Apoiar</a>.</p>"
            },
            eligibility: {
                subject: "DXC Equipamentos - Elegibilidade",
                header: "Parece que você está um pouco adiantado...",
                html: "<p class='text-left'>Obrigado por seu interesse na DXC Equipamentos. Parece que você chegou ao site antes de ser habilitado e convidado diretamente. A DXC Equipamentos está sendo lançada em etapas país por país e por grupos de usuários uma vez que as exigências regulatórias tenham sido cumpridas e que o inventário tenha sido feito. Quando você for ativado(a), receberá um e-mail contendo um convite pessoal com as instruções</p><p class='text-left'>Clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Suporte</a> para perguntas ou problemas com seu pedido ou DXC Equipamentos.</p>",
            },
            "eligibility-location": {
                header: "DXC Gear not available in your country...",
                html: "<p class='text-left'>Obrigado pelo seu interesse no DXC Gear, mas neste momento não há planos para lançar o DXC Gear no seu país.</p><p class='text-left'>Para obter mais informações sobre como encomendar acessórios para PC, consulte o artigo <b><a href='https://csc.service-now.com/sp?sys_kb_id=3724e21cdb224b08d417e1994b9619e7&id=kb_article_view&sysparm_rank=1&sysparm_tsqueryId=3a89aab687965d1811140f2acebb3537' target='_blank' rel='noopener'>Encomendar e substituir acessórios para PC</a></b>.</p>",
            },
            country: {
                header: "@:views.errors.403.header",
                html: " @:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "not-allowed": {
                header: "Acesso não aprovado",
                html: "<p class='text-left'>Obrigado pelo seu interesse no DXC Gear. Atualmente, isso está disponível apenas para funcionários da DXC. </p><p class='text-left'>Se você tiver mais dúvidas, clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>DXC Gear Apoiar</a>.</p>"
            },
            "no-permissions": {
                header: "Sem acesso",
                subject: "Sem acesso",
                html: "Você não tem permissão para abrir esta página.",
            }
        }
    },
    500: {
        title: "Erro inesperado",
        header: "@:views.errors.500.title",
        message: "Ocorreu um erro. Por favor, tente novamente mais tarde."
    },
    code: {
        title: "Erro inesperado",
        message: "@:views.errors.code.title",
        error: "Erro {code}."
    }
}