import store from "@/store";
import permissions from "@/helpers/permissions";

export default {
    ...permissions,
    /**
     * Handles error page based on failed permissions check
     *
     * @param {String} permission
     * @param {Object} restriction
     * @returns {{name: string, params: {error: string}}}
     */
    getRouteByPermissionFail(permission, restriction) {

        if (restriction || store.getters["user/permissions/hasRestriction"](permission)) {

            restriction = restriction ? restriction : store.getters["user/permissions/getRestriction"](permission);

            if ("undefined" !== typeof restriction.isUserDisabled && true === restriction.isUserDisabled) {

                return {name: "errors.403", params: {error: "user-disabled"}};
            }

            if ("undefined" !== typeof restriction.isLoginFailure && true === restriction.isLoginFailure) {

                return {name: "errors.403", params: {error: "non-employees"}};
            }

            if ("undefined" !== typeof restriction.isUserInSupportedCountry && false === restriction.isUserInSupportedCountry) {

                return {name: "errors.403", params: {error: "eligibility-location"}};
            }

            if ("undefined" !== typeof restriction.isUserTypeAccessAllowed && false === restriction.isUserTypeAccessAllowed) {

                return {name: "errors.403", params: {error: "not-allowed"}};
            }

            if ("undefined" !== typeof restriction.isUserAllowed && false === restriction.isUserAllowed) {

                return {name: "errors.403", params: {error: "eligibility"}};
            }
        }

        return permissions.getRouteByPermissionFail(permission, restriction);
    }
}