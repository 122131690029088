<template>
    <div v-if="true === groupingLoading && true === loading" class="container">
        <div class="row">
            <div class="col-12">
                <loading/>
            </div>
        </div>
    </div>
    <layout-two-column v-else>
        <template v-if="0 < groupings.length" #column>
            <panel>
                <tree-view :items="groupings" :value="groupingActiveId" @select="navigate"/>
            </panel>
        </template>

        <template #default>
            <loading v-if="loading"/>
            <products-product-page v-else-if="product" :value="product"/>
        </template>
    </layout-two-column>
</template>

<script>

    import {mapGetters} from "vuex";

    import mixin_view from "@/mixins/view";
    import mixin_products from "@/mixins/products";
    import {retryRequest} from "@/helpers/request";

    import LayoutTwoColumn from "@/components/layout/LayoutTwoColumn";
    import Panel from "@/components/Panel";
    import ProductsProductPage from "@/components/products/product/ProductsProductPage";
    import TreeView from "@/components/TreeView";

    export default {
        name: "ViewsProductsProduct",
        mixins: [mixin_view, mixin_products],
        components: {
            LayoutTwoColumn,
            Panel,
            ProductsProductPage,
            TreeView
        },
        data() {

            return {
                t_path: "views.products.product",
                loading: false,
                product: null
            }
        },
        computed: {
            ...mapGetters({
                groupings: "grouping/items",
                groupingActiveId: "grouping/activeId",
                groupingLoading: "grouping/loading"
            })
        },
        methods: {
            navigate(id) {

                this.$router.push({
                    name: "products",
                    params: {
                        grouping: this.$route.params.grouping,
                        grouping_id: id
                    }
                });
            },
            fetch() {

                this.loading = true;

                return this
                    .$store
                    .dispatch("grouping/fetch", {
                        type: this.$route.params.grouping,
                        activeId: this.$route.params.grouping_id
                    })
                    .then(() => retryRequest(() => this.$platform.get(`/api/v2/grouping/product/${this.$route.params.sku}`)))
                    .then(response => this.product = response.data.results[0])
                    .finally(() => this.loading = false);
            },
            refresh() {

                this.products_updateBreadcrumbsProduct(this.product);
                this.view_setTitle(this.products_translateProductName(this.product));
            }
        },
        watch: {
            "$i18n.locale": function () {

                this.refresh();
            },
            product() {

                this.refresh();
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>