<template>
    <panel class="d-flex">
        <div class="products-list-product position-relative">

            <div class="d-none d-md-block">
                <products-product-thumbnails v-if="0 < value.pictures.length"
                                             :route="{name:'products.product', params: {grouping:grouping, grouping_id:groupingId,sku:value.sku}}"
                                             :value="value.pictures"/>
            </div>
            <div class="d-md-none" v-if="mainPicture">
                <router-link
                        :to="{name:'products.product', params: {grouping:grouping, grouping_id:groupingId,sku:value.sku}}">
                    <img :src="mainPicture" :alt="name" class="image"/>
                </router-link>
            </div>

            <div class="title flex-grow-1">
                <router-link class="products-list-product-title-link"
                             :to="{name:'products.product', params: {grouping:grouping, grouping_id:groupingId,sku:value.sku}}">
                    {{name}}
                </router-link>

                <products-product-estimated-ship-date class="note pt-2 font-italic" :value="value"/>

                <div class="note" v-if="note" v-html="note"></div>

                <products-list-product-description :description="description"
                                                   :route="{name:'products.product', params: {grouping:grouping, grouping_id:groupingId,sku:value.sku}}"
                                                   :max-height="40"/>
            </div>

            <div class="text-center pt-3 pb-3">
                <products-product-cart class="btn-cart" :value="value" @add="add"/>

                <div class="cart-toast">
                    <div class="message" :class="{'display':added.display}">
                        {{t('added')}}
                    </div>
                </div>
            </div>

        </div>
    </panel>
</template>

<script>


    import mixin_products from "@/mixins/products";

    import Panel from "../Panel";
    import ProductsProductThumbnails from "./product/ProductsProductThumbnails";
    import ProductsListProductDescription from "./ProductsListProductDescription";
    import ProductsProductCart from "@/components/products/ProductsProductCart";
    import ProductsProductEstimatedShipDate from "@/components/products/product/ProductsProductEstimatedShipDate";

    export default {
        name: "ProductsListProduct",
        mixins: [mixin_products],
        components: {
            ProductsProductCart,
            ProductsListProductDescription,
            Panel,
            ProductsProductThumbnails,
            ProductsProductEstimatedShipDate
        },
        props: {
            value: {
                type: Object,
                required: true
            },
            grouping: {
                type: String,
                required: true,
            },
            groupingId: {
                type: [Number, String],
                required: true
            }
        },
        data() {

            return {
                t_path: "components.products.list.product",

                added: {
                    timeout: null,
                    display: false
                }
            }
        },
        computed: {
            mainPicture() {

                if (0 === this.value.pictures.length) {

                    return null;
                }

                return this.value.pictures[0].medium;
            },
            name() {

                return this.products_translateProductName(this.value);
            },
            note() {

                return this.products_translateProductNote(this.value);
            },
            description() {

                return this.products_translateProductDescription(
                    this.value,
                    ["b", "i", "em", "strong", "br", "ul", "ol", "li"]
                );
            }
        },
        methods: {
            add() {

                clearTimeout(this.added.timeout);
                this.added.display = true
                this.added.timeout = setTimeout(() => this.added.display = false, 1000);
            }
        }
    }
</script>